import React, { useState, useEffect } from 'react';
import {  Table, InputGroup, FormControl } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

const InputForm = ({ setData }) => {
  const currentYear = new Date().getFullYear();

  const [stockSearch, setStockSearch] = useState('');
  // const currentYear = new Date().getFullYear();
  const [fcf, setFcf] = useState(
    Array.from({ length: 10 }, (_, i) => ({ year: currentYear - 10 + i, value: '' }))
  );
  const [averageGrowthRate, setAverageGrowthRate] = useState('');
  const [selectedGrowthRate, setSelectedGrowthRate] = useState('');
  const [futureFCF, setFutureFCF] = useState([]);

  const [pvOfFFCF, setPvOfFFCF] = useState([]);







  const [cashAndCashEquivalents, setCashAndCashEquivalents] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [sharesOutstanding, setSharesOutstanding] = useState(0);
  const [equityValue, setEquityValue] = useState(0);
  const [dcfPricePerShare, setDcfPricePerShare] = useState(0);



  const [perpetualGrowthRate, setPerpetualGrowthRate] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [sumOfPvOfFFCF, setSumOfPvOfFFCF] = useState(0);
  const [years, setYears] = useState([]);


  const handleFcfChange = (index, field, value) => {
    const newFcf = [...fcf];
    newFcf[index][field] = value;
    setFcf(newFcf);
  };

  useEffect(() => {
    const averageGrowthRate = fcf.reduce((acc, curr, index, arr) => {
      if (index !== 0 && arr[index - 1].value !== '' && curr.value !== '') {
        const growthRate = ((curr.value - arr[index - 1].value) / arr[index - 1].value) * 100;
        return acc + growthRate;
      }
      return acc;
    }, 0) / (fcf.length - 1);
    setAverageGrowthRate(averageGrowthRate.toFixed(2));
    // setSelectedGrowthRate(averageGrowthRate);
  }, [fcf]);

  useEffect(() => {
    let last_fcf = fcf[fcf.length - 1];
    if (last_fcf !== null) {
      let newYears = Array.from({ length: 9 }, (_, i) => parseInt(last_fcf.year) + i);
      newYears.push("Terminal Value");
      setYears(newYears);
    }
  }, [fcf]);


  useEffect(() => {
    if (fcf.length > 0 && selectedGrowthRate !== '' && perpetualGrowthRate !== '') {
      var previous_year_val = fcf[fcf.length - 1].value * (1 + parseFloat(selectedGrowthRate) / 100);
      const newFutureFCF = fcf.map((value, index, arr) => {
        if (index === 0) {
          return previous_year_val;
        }
        if (index === arr.length - 1) {
          return previous_year_val * (1 + parseFloat(perpetualGrowthRate) / 100) / (parseFloat(discountRate) / 100 - parseFloat(perpetualGrowthRate) / 100);
        }
        let cur_year_val = previous_year_val * (1 + parseFloat(selectedGrowthRate) / 100);
        previous_year_val = cur_year_val;
        return cur_year_val;
      });
      setFutureFCF(newFutureFCF);
    }
  }, [fcf, selectedGrowthRate]);

  useEffect(() => {
    if (futureFCF.length > 0 && discountRate !== '') {
      const newPvOfFFCF = futureFCF.map((value, index) => {
        return value / Math.pow((1 + parseFloat(discountRate) / 100), index + 1);
      });
      setPvOfFFCF(newPvOfFFCF);
    }
  }, [futureFCF, discountRate]);

  useEffect(() => {
    const sum = pvOfFFCF.reduce((a, b) => a + b, 0);
    setSumOfPvOfFFCF(sum);
  }, [pvOfFFCF]);



  useEffect(() => {
    const equityVal = sumOfPvOfFFCF + parseFloat(cashAndCashEquivalents) - totalDebt;
    setEquityValue(equityVal);
    setDcfPricePerShare(equityVal / sharesOutstanding);
  }, [sumOfPvOfFFCF, cashAndCashEquivalents, totalDebt, sharesOutstanding]);



  // Other functions...
  // create a function called handleSubmit that takes in an event as an argument
  const handleSubmit = (e) => {
    e.preventDefault();
    // create a variable called fcfData that is equal to the fcf array
    const fcfData = fcf
      // filter out any objects that have an empty year or value
      .filter((field) => field.year && field.value)
      // map over the array and return an object with the year and value as keys
      .map((field) => ({ year: parseInt(field.year), value: parseFloat(field.value) }));

    // call the setData function with the stockSearch and fcfData as arguments
    setData(stockSearch, fcfData);
  }

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search by stock"
              aria-label="Search by stock"
              aria-describedby="basic-addon2"
              value={stockSearch}
              onChange={(e) => setStockSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-5">
        <Col md={12}>
          {/* <Form onSubmit={handleSubmit}> */}
          <Table striped bordered hover style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Year</th>
                {fcf.map((_, index) => (
                  <th key={index}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Year ${index + 1}`}
                      value={fcf[index].year}
                      onChange={(e) => handleFcfChange(index, 'year', e.target.value)}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>FCF</th>
                {fcf.map((_, index) => (
                  <td key={index}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Value ${index + 1}`}
                      value={fcf[index].value}
                      onChange={(e) => handleFcfChange(index, 'value', e.target.value)}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th>FCF Growth (%)</th>
                {fcf.map((_, index) => (
                  <td key={index}>
                    {index !== 0 && fcf[index - 1].value !== '' && fcf[index].value !== ''
                      ? (((fcf[index].value - fcf[index - 1].value) / fcf[index - 1].value) * 100).toFixed(2) + '%'
                      : ''}
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className='justify-content-md-left mt-5'>
        <Col md={3}>
          <Table triped bordered hover>
            <thead>
              <tr>
                <th colSpan="2" className="text-center">Growth Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Average Growth rate</td>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {(
                    fcf.reduce((acc, curr, index, arr) => {
                      if (index !== 0 && arr[index - 1].value !== '' && curr.value !== '') {
                        const growthRate = ((curr.value - arr[index - 1].value) / arr[index - 1].value) * 100;
                        return acc + growthRate;
                      }
                      return acc;
                    }, 0) / (fcf.length - 1)
                  ).toFixed(2) + '%'}
                </td>
              </tr>
              <tr>
                <td>Selected growth rate</td>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    style={{ width: '50%' }}
                    value={selectedGrowthRate}
                    onChange={(e) => setSelectedGrowthRate(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-5">
        <Table striped bordered hover style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Year</th>
              {years.map((year, index) => (
                <th key={index}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Future FCF</td>
              {futureFCF.map((fcf, index) => (
                <td key={index}>{fcf}</td>
              ))}
            </tr>
            <tr>
              <td>PV of FFCF</td>
              {pvOfFFCF.map((pv, index) => (
                <td key={index}>{pv}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Row>
      <Row className='justify-content-md-left mt-5'>
        <Col md={3}>
          <Table triped bordered hover>
            <thead>
              <tr>
                <th colSpan="2" className="text-center">Rates</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Perpetual Growth Rate</td>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    style={{ width: '40%' }}
                    value={perpetualGrowthRate}
                    onChange={(e) => setPerpetualGrowthRate(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Discount rate</td>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input
                    defaultValue={10}
                    type="number"
                    className="form-control form-control-sm"
                    style={{ width: '40%' }}
                    value={discountRate}
                    onChange={(e) => setDiscountRate(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-5">
        <Table striped bordered hover style={{ width: '50%' }}>
          {/* <thead>
            <tr>
              <th>Sum of FCF</th>
              <th></th>
              <th>Total Debt</th>
              <th>Equity Value</th>
              <th>Shares Outstanding</th>
              <th>DCF Price per Share</th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <td>Sum of FCF</td>
              <td>${sumOfPvOfFFCF.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Cash and Cash Equivalents</td>
              <td><input type="number" value={cashAndCashEquivalents} onChange={e => setCashAndCashEquivalents(e.target.value)} /></td>
            </tr>
            <tr>
              <td>Total Debt</td>
              <td><input type="number" value={totalDebt} onChange={e => setTotalDebt(e.target.value)} /></td>
            </tr>
            <tr>
              <td>Equity Value</td>
              <td>${equityValue.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Shares Outstanding</td>
              <td><input type="number" value={sharesOutstanding} onChange={e => setSharesOutstanding(e.target.value)} /></td>
            </tr>
            <tr>
              <td>DCF Price per Share</td>
              <td>${dcfPricePerShare.toFixed(2)}</td>
            </tr>

          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default InputForm;

