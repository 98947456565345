import React, { useState } from 'react';
import InputForm from './InputForm';
import Results from './Results';
import './App.css';

function App() {
  const [data, setData] = useState(null);

  return (
    <InputForm setData={setData} />
  );
}

export default App;
